<template>
  <ListView
    title="Physical Product"
    @navDetails="actionDetails"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :enable-create="!isMaster"
    :loading="isLoading"
  >
    <template #filterRight>
      <v-select
        v-model="queryFilter.category"
        :items="productCategories"
        item-text="title"
        item-value="_id"
        label="Category"
        clearable
        solo
        height="56"
        style="width: 224px"
      />
    </template>
    <template v-slot:action>
      <v-btn text @click="reload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn icon color="primary" @click="actionCreate">
        <v-icon dense>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn class="mx-3" @click="updateByCSV"> Update By CSV </v-btn>
      <v-btn @click="importNewProduct"> Import New Product </v-btn>
    </template>

    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | moment }}
    </template>
    <!-- <template v-slot:item.name_cn="{ item }">
      {{ parseLocalizedString(item.name)['zh-cn'] }}
    </template> -->
    <template v-slot:item.name_en="{ item }">
      {{ parseLocalizedString(item.name).en }}
    </template>
    <template v-slot:item.name_hk="{ item }">
      {{ parseLocalizedString(item.name)['zh-hk'] }}
    </template>
    <template v-slot:item.featuredImage="{ item }">
      <v-img
        v-if="item.featuredImage || item.images[0]"
        :src="attnThumbHref(item.featuredImage || item.images[0], '64')"
        height="64"
        class="thumbnail"
        @click.stop="
          () => {
            selectedPhysicalProduct = item;
            displayImage = true;
          }
        "
      />
    </template>
    <template v-slot:item.listedPrice="{ item }">
      <span v-if="item.listedPrice">${{ item.listedPrice | toThousandFilter }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item.discountedPrice="{ item }">
      <span v-if="item.discountedPrice">${{ item.discountedPrice | toThousandFilter }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item.cost="{ item }">
      <span v-if="item.cost">${{ item.cost | toThousandFilter }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item.description="{ item }">
      <div style="max-width: 150px" class="d-inline-block text-truncate">
        {{ item.description }}
      </div>
    </template>
    <template v-slot:item.stock="{ item }">
      <span v-if="item.stock >= 0">{{ item.stock | toThousandFilter }}</span>
      <span v-else-if="item.stock === -1"><v-icon>mdi-all-inclusive</v-icon></span>
      <span v-else>N/A</span>
    </template>
    <ProductEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      :productCategories="productCategories"
      :productSuppliers="productSuppliers"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
    <CarouselDialog v-model="displayImage" :images="selectedImages" />
    <UpdateProductListDialog
      v-model="updateProductListDialogRef"
      @close="updateProductListDialogRef = false"
      @reload="reload"
    ></UpdateProductListDialog>
    <ImportNewProductDialog
      v-model="importNewProductDialogRef"
      @close="importNewProductDialogRef = false"
      @reload="reload"
    ></ImportNewProductDialog>
  </ListView>
</template>

<script>
import CarouselDialog from '@/components/CarouselDialog.vue';
import ProductEditDialog from '@/components/PhysicalProduct/ProductEditDialog';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { mapGetters } from 'vuex';
import { parseLocalizedString } from '@/services/utils';
import { attnThumbHref } from '@/services/filters';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import moment from 'moment';
import { listSuppliers } from '@/api/suppliers';
import UpdateProductListDialog from '@/components/PhysicalProduct/UpdateProductListDialog';
import ImportNewProductDialog from '@/components/PhysicalProduct/ImportNewProductDialog';

export default {
  name: 'PhysicalProducts',
  data() {
    return {
      header: [
        { text: 'Created At', value: 'created_at' },
        { text: 'Category', value: 'category.title' },
        // { text: 'Product Name(CN)', value: 'name_cn' },
        { text: 'Product Name(EN)', value: 'name_en' },
        { text: 'Product Name(HK)', value: 'name_hk' },
        { text: 'Description', value: 'description' },
        { text: 'Image(s)', value: 'featuredImage' },
        { text: 'Listed Price', value: 'listedPrice' },
        { text: 'Discounted Price', value: 'discountedPrice' },
        { text: 'Cost', value: 'cost' },
        // { text: 'Stock', value: 'stock' }, // stock is store / warehouse related
      ],
      populate: ['category'],
      queryFilter: {},
      productCategories: [],
      displayImage: false,
      selectedPhysicalProduct: null,
      productSuppliers: [],
      updateProductListDialogRef: false,
      importNewProductDialogRef: false,
    };
  },
  mixins: [listViewPageMixin],
  components: {
    CarouselDialog,
    ProductEditDialog,
    CarouselDialog,
    UpdateProductListDialog,
    ImportNewProductDialog,
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return this.isMaster ? RESTFUL.physicalProducts.masterList : RESTFUL.physicalProducts.list;
    },
    filter() {
      if (this.queryFilter.category === null) this.queryFilter.category = undefined;
      return { ...this.queryFilter };
    },
    selectedImages() {
      const list = this.selectedPhysicalProduct?.featuredImage
        ? [this.selectedPhysicalProduct?.featuredImage]
        : [];
      return list.concat(this.selectedPhysicalProduct?.images || []);
    },
  },
  mounted() {
    this.getProductCategories();
    this.getProductSuppliers();
  },
  methods: {
    api,
    moment,
    attnThumbHref,
    parseLocalizedString,
    async getProductCategories() {
      this.productCategories = await listProductCategories(api);
    },
    async getProductSuppliers() {
      this.productSuppliers = await listSuppliers(api);
    },
    updateByCSV() {
      this.updateProductListDialogRef = true;
    },
    importNewProduct() {
      this.importNewProductDialogRef = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  cursor: zoom-in;
}
</style>
