var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListView',{attrs:{"title":"Physical Product","search":_vm.search,"data":_vm.data,"page":_vm.page,"header":_vm.header,"enable-create":!_vm.isMaster,"loading":_vm.isLoading},on:{"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"filterRight",fn:function(){return [_c('v-select',{staticStyle:{"width":"224px"},attrs:{"items":_vm.productCategories,"item-text":"title","item-value":"_id","label":"Category","clearable":"","solo":"","height":"56"},model:{value:(_vm.queryFilter.category),callback:function ($$v) {_vm.$set(_vm.queryFilter, "category", $$v)},expression:"queryFilter.category"}})]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.actionCreate}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-plus-circle-outline")])],1),_c('v-btn',{staticClass:"mx-3",on:{"click":_vm.updateByCSV}},[_vm._v(" Update By CSV ")]),_c('v-btn',{on:{"click":_vm.importNewProduct}},[_vm._v(" Import New Product ")])]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at))+" ")]}},{key:"item.name_en",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseLocalizedString(item.name).en)+" ")]}},{key:"item.name_hk",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseLocalizedString(item.name)['zh-hk'])+" ")]}},{key:"item.featuredImage",fn:function(ref){
var item = ref.item;
return [(item.featuredImage || item.images[0])?_c('v-img',{staticClass:"thumbnail",attrs:{"src":_vm.attnThumbHref(item.featuredImage || item.images[0], '64'),"height":"64"},on:{"click":function($event){$event.stopPropagation();return (function () {
          _vm.selectedPhysicalProduct = item;
          _vm.displayImage = true;
        }).apply(null, arguments)}}}):_vm._e()]}},{key:"item.listedPrice",fn:function(ref){
        var item = ref.item;
return [(item.listedPrice)?_c('span',[_vm._v("$"+_vm._s(_vm._f("toThousandFilter")(item.listedPrice)))]):_c('span',[_vm._v("-")])]}},{key:"item.discountedPrice",fn:function(ref){
        var item = ref.item;
return [(item.discountedPrice)?_c('span',[_vm._v("$"+_vm._s(_vm._f("toThousandFilter")(item.discountedPrice)))]):_c('span',[_vm._v("-")])]}},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [(item.cost)?_c('span',[_vm._v("$"+_vm._s(_vm._f("toThousandFilter")(item.cost)))]):_c('span',[_vm._v("-")])]}},{key:"item.description",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.stock",fn:function(ref){
        var item = ref.item;
return [(item.stock >= 0)?_c('span',[_vm._v(_vm._s(_vm._f("toThousandFilter")(item.stock)))]):(item.stock === -1)?_c('span',[_c('v-icon',[_vm._v("mdi-all-inclusive")])],1):_c('span',[_vm._v("N/A")])]}}])},[_c('ProductEditDialog',{attrs:{"id":_vm.editId,"title":_vm.title,"productCategories":_vm.productCategories,"productSuppliers":_vm.productSuppliers},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('CarouselDialog',{attrs:{"images":_vm.selectedImages},model:{value:(_vm.displayImage),callback:function ($$v) {_vm.displayImage=$$v},expression:"displayImage"}}),_c('UpdateProductListDialog',{on:{"close":function($event){_vm.updateProductListDialogRef = false},"reload":_vm.reload},model:{value:(_vm.updateProductListDialogRef),callback:function ($$v) {_vm.updateProductListDialogRef=$$v},expression:"updateProductListDialogRef"}}),_c('ImportNewProductDialog',{on:{"close":function($event){_vm.importNewProductDialogRef = false},"reload":_vm.reload},model:{value:(_vm.importNewProductDialogRef),callback:function ($$v) {_vm.importNewProductDialogRef=$$v},expression:"importNewProductDialogRef"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }